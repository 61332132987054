import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import BannerPage from "./pages/BannerPage/BannerPage";

export default function BasicExample() {
  return (
    <Router>
      <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/about/me">About me</Link>
          </li>
          <li>
            <Link to="/about/you">About you</Link>
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
        </ul>

        <hr />

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <BannerPage paragraph="you are on /" />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/dashboard">
            <BannerPage paragraph="you are on /dashboard" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function About() {
  let { path, url } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <BannerPage paragraph="you are on /about" />
        </Route>
        <Route path={`${path}/me`}>
          <BannerPage paragraph="you are on /about/me" />
        </Route>
        <Route path={`${path}/you`}>
          <BannerPage paragraph="you are on /about/you" />
        </Route>
      </Switch>
    </div>
  );
}