import './BannerPage.css';

type CardProps = {
  title: string,
  paragraph: string
}

export default ({ title, paragraph }: CardProps) => <aside>
  <div className="BannerPage">
      {paragraph}
    </div>
</aside>
